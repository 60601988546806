import { string } from 'yup';
import { ICreateOrganizationData } from '../steamcord';
import { IErrorAggregate } from './errorAggregate';
import { ISettings } from '../../entities/settings';
import { requiredStringSchema, validate } from '.';

const nameFormatMessage = 'Organization names must be between 4 and 32 characters.';
const slugFormatMessage = 'Subdomains must be between 4 and 32 characters with only lowercase '
  + 'letters.';
const faviconURIFormatMessage = 'Favicon URIs must use HTTPS (https://).';
const logoURIFormatMessage = 'Logo URLs must use HTTPS (https://).';
const discordRoleIDFormatMessage = 'Invalid Discord role ID.';
const discordInviteFormatMessage = 'Discord invite link invalid.';
const steamIDFormatMessage = 'Invalid Steam group ID.';
const steamURIFormatMessage = 'Steam group URL invalid.';

const httpsRegex = /^https:\/\//;
const discordRegex = /^(?:https?:\/\/)?(?:www\.)?discord(?:app)?\.(?:gg|com\/invite)\/[\w-]+$\/?/;
const steamGroupRegex = /^https?:\/\/steamcommunity\.com\/groups\/[\w-]+$\/?/;
const number = /^\d+$/;

function createOptionalSchema(regex: RegExp, message: string) {
  return string().matches(regex, {
    excludeEmptyString: true,
    message,
  }).nullable();
}

const validationSchema = {
  name: requiredStringSchema.matches(/^\S.{2,32}\S$/, nameFormatMessage),
  slug: requiredStringSchema.matches(/^[a-z-]{4,32}$/, slugFormatMessage),
  logoURI: createOptionalSchema(httpsRegex, logoURIFormatMessage),
  faviconURI: createOptionalSchema(httpsRegex, faviconURIFormatMessage),
  discordRoleID: createOptionalSchema(number, discordRoleIDFormatMessage),
  discordInvite: createOptionalSchema(discordRegex, discordInviteFormatMessage),
  steamGroupID: createOptionalSchema(number, steamIDFormatMessage),
  steamGroupURI: createOptionalSchema(steamGroupRegex, steamURIFormatMessage),
};

export interface ICreateOrganizationErrors extends IErrorAggregate {
  nameError: string;
  slugError: string;
}

export function validateCreateOrgData({ name, slug }: ICreateOrganizationData):
  ICreateOrganizationErrors {
  const errors = {
    nameError: validate(validationSchema.name, name),
    slugError: validate(validationSchema.slug, slug),
    hasErrors: false,
  };

  errors.hasErrors = !!(errors.nameError || errors.slugError);

  return errors;
}

export interface ISettingsErrors extends ICreateOrganizationErrors {
  nameError: string;
  slugError: string;
  faviconURIError: string;
  logoURIError: string;
  discordRoleIDError: string;
  discordInviteError: string;
  steamIDError: string;
  steamURIError: string;
}

export function validateSettings(settings: ISettings): ISettingsErrors {
  const errors = {
    ...validateCreateOrgData({ name: settings.name, slug: settings.slug }),
    faviconURIError: validate(validationSchema.faviconURI, settings.faviconUri),
    logoURIError: validate(validationSchema.logoURI, settings.logoUri),
    discordRoleIDError: validate(validationSchema.discordRoleID, settings.discordRoleId),
    discordInviteError: validate(validationSchema.discordInvite, settings.discordGuildInviteUri),
    steamIDError: validate(validationSchema.steamGroupID, settings.steamGroupId),
    steamURIError: validate(validationSchema.steamGroupURI, settings.steamGroupUri),
  };

  errors.hasErrors = !!(errors.hasErrors || errors.faviconURIError || errors.logoURIError
    || errors.discordRoleIDError || errors.discordInviteError || errors.steamIDError
    || errors.steamURIError);

  return errors;
}
