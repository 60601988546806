
import axios from 'axios';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { ISettings } from '../../entities/settings';
import { SubscriptionPlan } from '../../entities/subscriptionPlan';
import { IVanityDomain } from '../../entities/vanityDomain';
import { VanityDomainStatus } from '../../entities/vanityDomainStatus';
import { getSettings, updateSettings, updateSettingsdiscordGuild } from '@/services/steamcord';
import { validateSettings } from '@/services/validation/org';
import { useAuthStore } from '@/store/authStore';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import SavePopup from '@/components/dashboard/SavePopup.vue';

export default defineComponent({
  async beforeRouteEnter(to, from, next) {
    const { currentOrg } = useOrgStore();
    if (!currentOrg) {
      next({ name: 'Internal Server Error' });
      return;
    }

    const settings = await getSettings(currentOrg.id);
    let guildID: string;

    const { code } = to.query;
    if (code) {
      try {
        guildID = await updateSettingsdiscordGuild(currentOrg.id, code.toString());
      } catch (err) {
        if (!axios.isAxiosError(err) || err.response?.status !== 400) {
          throw err;
        }
      }
    }

    next((vm) => {
      (vm as any).setSettings(settings, guildID);
      vm.$router.replace({ params: undefined });
    });
  },
  components: {
    SavePopup,
  },
  computed: {
    isProPlan(): boolean {
      return this.currentOrg?.subscriptionPlan === SubscriptionPlan.Pro;
    },
    ...mapState(useAuthStore, ['token']),
    ...mapWritableState(useMainStore, {
      appLoading: 'loading',
    }),
    ...mapWritableState(useOrgStore, ['currentOrg']),
    settingsChanged(): boolean {
      return !!this.original && JSON.stringify(this.settings) !== this.original;
    },
    vanityDomainPending(): boolean {
      return this.settings.vanityDomain.status === VanityDomainStatus.Pending;
    },
    vanityDomainErrored(): boolean {
      return this.settings.vanityDomain.status === VanityDomainStatus.ValidationFailed;
    },
    vanityDomainActive(): boolean {
      return this.settings.vanityDomain.status === VanityDomainStatus.Active;
    },
  },
  data() {
    return {
      loading: false,
      original: '',
      settings: {
        name: '',
        slug: '',
        title: '',
        faviconUri: '',
        vanityDomain: {
          domain: '',
          status: undefined,
        } as IVanityDomain,
        allowMultipleAccounts: false,
        logoUri: '',
        rewardsText: '',
        discordGuildId: '',
        discordRoleId: '',
        discordRoleRequirements: {
          discord: false,
          discordGuildMember: false,
          discordGuildBooster: false,
          steam: false,
          steamGroupMember: false,
        },
        discordGuildInviteUri: '',
        discordRewardsText: '',
        steamGroupId: '',
        steamGroupUri: '',
        steamRewardsText: '',
      },
      settingsErrors: {
        nameError: '',
        slugError: '',
        faviconURIError: '',
        logoURIError: '',
        discordRoleIDError: '',
        discordInviteError: '',
        steamIDError: '',
        steamURIError: '',
        hasErrors: false,
      },
    };
  },
  methods: {
    async onSubmit() {
      if (this.loading || !this.currentOrg) {
        return;
      }

      this.settingsErrors = validateSettings(this.settings);
      if (this.settingsErrors.hasErrors) {
        return;
      }

      this.loading = true;
      const [errors, settings] = await updateSettings(this.currentOrg.id, this.settings);
      if (errors?.Slug) {
        [this.settingsErrors.slugError] = errors.Slug;
      } else if (settings) {
        this.setSettings(settings, settings.discordGuildId);
      }

      if (this.currentOrg) {
        this.currentOrg.slug = this.settings.slug;
        this.currentOrg.name = this.settings.name;
      }

      this.loading = false;
    },
    setSettings(settings: ISettings, guildID: string) {
      this.settings = settings;

      if (guildID) {
        this.settings.discordGuildId = guildID;
      }

      this.original = JSON.stringify(settings);
    },
  },
  async mounted() {
    this.appLoading = false;

    if (!this.isProPlan) {
      return;
    }

    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_BASE_URI}/hubs/vanity-domains`, {
        accessTokenFactory: () => this.token as string,
      })
      .withAutomaticReconnect()
      .build();

    connection.on('statusUpdated', (status: VanityDomainStatus) => {
      this.settings.vanityDomain.status = status;
      this.setSettings(this.settings, this.settings.discordGuildId);
    });

    await connection.start();

    await connection.invoke('Subscribe', this.currentOrg?.id);
  },
});
