
import { defineComponent } from 'vue';
import SaveSpacer from './SaveSpacer.vue';

export default defineComponent({
  components: {
    SaveSpacer,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    showSpacer: {
      type: Boolean,
      default: true,
    },
  },
});
